import { UAParser } from "ua-parser-js";
import { z } from "zod";
import FingerprintJS, { Agent } from "@fingerprintjs/fingerprintjs";

const FINGERPRINT_LS_KEY = "sfpk";

interface GetVisitorPropertiesProp<T> {
  encoded?: T;
}

export const VisitorSchema = z.object({
  visitor_id: z.string().optional(),
  os: z.object({
    name: z.string().optional(),
    version: z.string().optional(),
  }),
});

export type VisitorProperties = z.infer<typeof VisitorSchema>;

const FingerprintClient = () => {
  let instance: Agent | undefined;
  let visitorProperties: VisitorProperties;

  /**
   * Gets visitor properties
   * @returns VisitorProperties object
   */
  const getVisitorProperties = <T extends boolean = false>(
    props?: GetVisitorPropertiesProp<T>
  ): T extends false ? VisitorProperties : string => {
    const { encoded } = props ?? {};
    return (
      encoded
        ? Buffer.from(JSON.stringify(visitorProperties)).toString("base64")
        : visitorProperties
    ) as T extends false ? VisitorProperties : string;
  };

  /**
   * Get user's fingerprint and sets visitor properties in segment instance
   * and caches value in localstorage
   */
  const registerFingerprint = async () => {
    try {
      if (!instance) throw Error("No fp instance");
      const result = await instance.get();
      const parser = new UAParser();
      const { os } = parser.getResult();
      // Build the full visitor properties
      visitorProperties = {
        visitor_id: result.visitorId,
        os: {
          name: os.name,
          version: os.version,
        },
      };
      const encodedVisitorProperties = getVisitorProperties({ encoded: true });
      localStorage.setItem(FINGERPRINT_LS_KEY, encodedVisitorProperties);
      return visitorProperties;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  /**
   * Validate encoded fingerprint in localstorage
   * @param fingerprint encoded string
   * @returns
   */
  const validateFingerprint = (fingerprint: string) => {
    const decodedFpString = Buffer.from(fingerprint, "base64").toString(
      "ascii"
    );
    const decodedVisitorProperty = JSON.parse(decodedFpString); // Using try catch due to potential error from JSON parse
    const validation = VisitorSchema.safeParse(decodedVisitorProperty);
    if (validation.success) {
      return validation.data;
    }
    throw Error();
  };

  /**
   * Initialise fingerprint client
   */
  const initiate = async () => {
    if (!instance) {
      instance = await FingerprintJS.load({ monitoring: false });
    }
    const sfpk = localStorage.getItem(FINGERPRINT_LS_KEY); // Get cached fingerprint from LS

    // Setup fingerprint if none is cached.
    if (!sfpk) {
      await registerFingerprint();
    } else {
      // validate cached fingerprint value
      try {
        visitorProperties = validateFingerprint(sfpk);
      } catch (err) {
        console.error("Invalid fp cache, reinitiating");
        await registerFingerprint();
      }
    }
  };

  return {
    initiate,
    getVisitorProperties,
  };
};

// Freeze singleton
export const fingerprintClient = Object.freeze(FingerprintClient());
