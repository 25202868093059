import type { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    colorPrimary: "#4142C9",
    colorPrimaryActive: "#302ca3",
    colorPrimaryHover: "#676bd6",
    colorError: "#FF5273",
    colorInfo: "#4142C9",
    colorLink: "#4142C9",
    colorInfoText: "#4142C9",
    colorPrimaryText: "#4142C9",
    colorSuccess: "#41CC4F",
    colorTextBase: "#111827",
    colorWarning: "#FFCF0D",
    colorText: "#201C49",
    fontWeightStrong: 500,
  },
  components: {
    Menu: {
      itemHoverBg: "#FAFAFA",
      subMenuItemBg: "#FFFFFF",
      itemBorderRadius: 4,
    },
    Radio: {
      buttonSolidCheckedBg: "#302ca3",
    },
    Tooltip: {
      fontSize: 12,
    },
  },
};

export default theme;
