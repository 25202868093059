import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src450579962/src/trustana-pim-fe/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["SecureCookiesProvider"] */ "/codebuild/output/src450579962/src/trustana-pim-fe/node_modules/next-client-cookies/dist/provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src450579962/src/trustana-pim-fe/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src450579962/src/trustana-pim-fe/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src450579962/src/trustana-pim-fe/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ClientProviders"] */ "/codebuild/output/src450579962/src/trustana-pim-fe/src/components/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src450579962/src/trustana-pim-fe/src/styles/appGlobals.css");
