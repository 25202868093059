import { CascaderOption } from "../types/categories";
import { ReactNode } from "react";

interface TreeNode {
  value: number;
  children?: TreeNode[];
}

export const buildTreePath = (
  node: TreeNode,
  path: number[],
  categoryTreePath: { [key: string]: number[] }
) => {
  const { value, children } = node;
  const currentPath = [...path, value];
  categoryTreePath[value] = currentPath;
  if (children && children.length > 0) {
    children.forEach((node) =>
      buildTreePath(node, currentPath, categoryTreePath)
    );
  }
};

export const searchTree = (
  tree: CascaderOption,
  node: number
): CascaderOption | null => {
  let result = null;
  if (node === tree.value) {
    return tree;
  }
  if (tree?.children) {
    tree.children.some((n) => {
      result = searchTree(n, node);
      return result;
    });
  }
  return result;
};

export const searchTreeLabel = (search: string, label: ReactNode) =>
  `${label}`.toLowerCase().indexOf(search.toLowerCase()) >= 0;
