"use client";

import { Namespace } from "@/src/i18n/namespace";
import { useTranslations } from "next-intl";
import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { createContext, useEffect, useState } from "react";
export type ProxyInstance = [string | undefined, (tips?: string) => void];
const NavBackBlockerContext = createContext<ProxyInstance>([undefined, () => {}]);
const NavBackBlockerProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const tCommon = useTranslations(Namespace.COMMON);
  const [tips, setTips] = useState<string | undefined>();
  const msg = tips === undefined ? tips : tips || tCommon("shared.pageLeaveWarning");
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const url = [pathname, searchParams].filter(i => i).join("?");
  useEffect(() => {
    setTips(undefined);
  }, [url, setTips]);
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (msg === undefined) return msg;
      event.preventDefault();
      event.returnValue = msg;
      return msg;
    };
    const script = document.getElementById("nav-back-proxy-script");
    if (script) {
      script.dataset.msg = msg || "";
      script.dataset.href = window.location.href;
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [msg]);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NavBackBlockerContext.Provider value={[msg, setTips]} data-sentry-element="unknown" data-sentry-component="NavBackBlockerProvider" data-sentry-source-file="NavBackBlockerProvider.tsx">
      <Script strategy="afterInteractive" id="nav-back-proxy-script" dangerouslySetInnerHTML={{
        __html: `(() => {
                        const originalPushState = history.pushState.bind(history);
                        let currentPoint = 0;
                        let point = 0;
                        window.history.pushState = function(state, title, url) {
                            state.point = ++point;
                            currentPoint = point;
                            originalPushState(state, title, url);
                        };
                        const originalReplaceState = history.replaceState.bind(history);
                        window.history.replaceState = function(state, title, url) {
                            state.point = currentPoint;
                            originalReplaceState(state, title, url);
                        };
                        window.addEventListener('popstate', function (event) {
                            const { state: nextState } = event;
                            const isback = currentPoint > nextState.point;

                            currentPoint = nextState.point;

                            const script = document.getElementById('nav-back-proxy-script');
                            if (!script || location.href === script.dataset.href) return;

                            const msg = script.dataset.msg||'';
                            const confirm = msg == '' ? true : window.confirm(msg);
                            if (!confirm) {
                                event.stopImmediatePropagation();
                                isback ? history.forward() : history.back();
                            }
                        });
                    })()`
      }} data-sentry-element="Script" data-sentry-source-file="NavBackBlockerProvider.tsx" />
      {children}
    </NavBackBlockerContext.Provider>
  );
};
export { NavBackBlockerContext };
export default NavBackBlockerProvider;