import { useEffect, useState } from "react";
import useAppContext from "@/src/components/appContext/useAppContext";
import { useApiServerAction } from "../useServerAction";
import useLocalStorageCache from "../useLocalStorageCache";
import { LocalStorageKey } from "../../enums/common";
import { DEFAULT_REVALIDATE_TIME } from "../../constants/cache";
import { CurrencyOption } from "@/src/types/currency";
import { ServerActionName } from "@/src/actions/enums";
import { CurrencyType } from "@/src/types/common";

export function useCurrencyOptions({
  filteredCurrency,
}: {
  filteredCurrency?: string[];
}) {
  const { ctx } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState<CurrencyOption[]>([]);

  useEffect(() => {
    if (ctx.currencies) {
      const list = ctx.currencies?.list;

      if (filteredCurrency) {
        setCurrency(
          list?.filter((item) => filteredCurrency.includes(item.value)) ?? []
        );
      } else {
        setCurrency(list ?? []);
      }

      setLoading(false);
    }
  }, [ctx.currencies]);

  return {
    loading,
    currency,
  };
}

// Only for root use.
export function useCurrenciesProvider() {
  const { setContext, ctx } = useAppContext();
  const isAuthenticated = !!ctx.user?.id;

  const getCurrencyList = useApiServerAction<null, CurrencyType[]>(
    ServerActionName.GetCurrencyList
  );

  const { value, lastUpdated } = useLocalStorageCache({
    key: LocalStorageKey.CurrencyList,
    revalidateTime: DEFAULT_REVALIDATE_TIME,
    shouldRevalidate: isAuthenticated,
    fetchValue: async () => {
      if (!isAuthenticated) {
        return { list: [] };
      }

      const response = await getCurrencyList();
      const result =
        response?.data?.map((item) => ({ label: item, value: item })) || [];

      if (result.length) {
        return {
          list: result,
        };
      }

      return null;
    },
  });

  const { list } = value ?? {};

  useEffect(() => {
    if (list?.length) {
      setContext((previousValue) => ({
        ...previousValue,
        currencies: {
          list,
          lastUpdated,
        },
      }));
    }
  }, [list]);
}
