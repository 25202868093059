"use client";

import { useEffect, useRef, useState } from "react";
import { buildTreePath, searchTree } from "@/src/utils/category";
import useAppContext from "@/src/components/appContext/useAppContext";
import {
  CascaderOption,
  GetGoogleCategoryListInput,
  GetGoogleCategoryListOutput,
} from "@/src/types/categories";
import { useApiServerAction } from "../useServerAction";
import useLocalStorageCache from "../useLocalStorageCache";
import { LocalStorageKey } from "@/src/enums/common";
import { DEFAULT_REVALIDATE_TIME } from "@/src/constants/cache";
import isNil from "lodash/isNil";
import { TreeSelectProps } from "@/src/pattern-library";
import { ServerActionName } from "@/src/actions/enums";
import { BackendGetLocaleGoogleCategoryInput } from "@/src/types/api/categories";

type CategoryTreePath = Record<number, number[]>;

const checkSelectableOption = (
  options: CascaderOption[]
): TreeSelectProps["treeData"] =>
  options.map((item) => {
    if (item.children) {
      return {
        ...item,
        selectable: false,
        children: checkSelectableOption(item.children),
      };
    }
    return item;
  });

export function useGoogleCategoryOptions() {
  const { ctx } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState<CascaderOption[]>([]);
  const [categoryTreePath, setCategoryTreePath] = useState<CategoryTreePath>(
    {}
  );
  const cachedLabels = useRef<Record<number, string>>({});

  useEffect(() => {
    if (ctx.googleCategory) {
      const list = ctx.googleCategory?.list;
      const treePath: CategoryTreePath = {};
      list?.forEach((node) => buildTreePath(node, [], treePath));

      setCategoryOptions(list || []);
      setCategoryTreePath(treePath);
      cachedLabels.current = {};
      setLoading(false);
    }
  }, [ctx.googleCategory]);

  const getCategoryNodeLabel = (categoryNode: number): string | undefined => {
    if (categoryNode in cachedLabels.current) {
      return cachedLabels.current[categoryNode];
    }
    const list = ctx.googleCategory?.list;
    if (list) {
      for (let i = 0; i < list.length; i += 1) {
        const result = searchTree(list[i], categoryNode);
        if (result) {
          cachedLabels.current = {
            ...cachedLabels.current,
            [categoryNode]: result.label,
          };
          return result.label;
        }
      }
    }
    return `${categoryNode}`;
  };

  const hasChildrenNodes = (categoryNode: number): boolean => {
    const list = ctx.googleCategory?.list;
    if (list) {
      for (let i = 0; i < list.length; i += 1) {
        const result = searchTree(list[i], categoryNode);
        const hasChild = !isNil(result?.children) && result.children.length > 0;
        if (hasChild) {
          return true;
        }
      }
    }
    return false;
  };

  return {
    loading,
    categoryOptions,
    categoryTreePath,
    getCategoryNodeLabel,
    hasChildrenNodes,
    singleSelectCategoryOptions: checkSelectableOption(categoryOptions),
  };
}

// Only for root use.
export function useGoogleCategoryProvider() {
  const { setContext, ctx } = useAppContext();
  const currLang = ctx.lang;
  const isAuthenticated = !!ctx.user?.id;

  const getGoogleCategoriesList = useApiServerAction<
    GetGoogleCategoryListInput & BackendGetLocaleGoogleCategoryInput,
    GetGoogleCategoryListOutput
  >(ServerActionName.GetGoogleCategoriesList);

  const { value, isLoading, lastUpdated, fetchAndUpdateCache } =
    useLocalStorageCache({
      key: LocalStorageKey.GoogleCategory,
      revalidateTime: DEFAULT_REVALIDATE_TIME,
      shouldRevalidate: isAuthenticated,
      fetchValue: async () => {
        const response = await getGoogleCategoriesList({
          parentCategoryId: 0,
          includeSubCategories: true,
          language: currLang,
        });

        const list = response?.data?.list;

        if (list?.length) {
          return {
            list,
            lang: currLang,
          };
        }

        return null;
      },
    });

  const { list, lang: cachedLang } = value ?? {};

  useEffect(() => {
    const needUpdateCache =
      cachedLang !== currLang && !isLoading && isAuthenticated;

    if (needUpdateCache) {
      fetchAndUpdateCache({ force: cachedLang !== currLang });
    } else if (list?.length) {
      setContext((previousValue) => ({
        ...previousValue,
        googleCategory: {
          list,
          lastUpdated,
        },
      }));
    }
  }, [list]);

  return {
    list,
    lastUpdated,
  };
}
