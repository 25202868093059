"use client";

import { ServerActionName } from "../actions/enums";
import { BffApiResponse } from "../types/api/common";

export function useApiServerAction<Args, Ret>(
  actionName: ServerActionName
): (args?: Args) => Promise<BffApiResponse<Ret>> {
  return async (args?: Args) => {
    const result = await fetch(`/api/action?actionName=${actionName}`, {
      method: "POST",
      body: args ? JSON.stringify(args) : JSON.stringify({}),
    });

    const data = await result.json();

    return data;
  };
}
