"use client";

import React, { useEffect, useRef } from "react";
import { ConfigProvider } from "@/src/pattern-library";
import { AppContextType } from "@/src/components/appContext/types";
import { segmentClient } from "@/src/utils/segment";
import segmentInit from "@/src/utils/segment/segmentInit";
import theme from "@/src/constants/themeConfig";
import { AppProvider, AppWindowType } from "@/src/components/appContext";
import { UserLanguage } from "@/src/enums/common";
import { Locale } from "antd/es/locale";
import * as Sentry from "@sentry/nextjs";
// Ant Design Languages
import en from "antd/locale/en_US";
import jp from "antd/locale/ja_JP";
import ko from "antd/locale/ko_KR";
import th from "antd/locale/th_TH";
import zh from "antd/locale/zh_CN";
import { usePathname } from "next/navigation";
import NavBackBlockerProvider from "./NavBackBlockerProvider";
import { NotificationProvider } from "@/src/pattern-library/notification/NotificationProvider";
const ANTD_LANGUAGE_MAPPING: Record<UserLanguage, Locale> = {
  [UserLanguage.EN]: en,
  [UserLanguage.JP]: jp,
  [UserLanguage.KO]: ko,
  [UserLanguage.TH]: th,
  [UserLanguage.ZH]: zh
};
export interface ProvidersProps {
  appContext: AppContextType;
  children: React.ReactNode;
}
export const ClientProviders: React.FC<ProvidersProps> = ({
  appContext,
  children
}: ProvidersProps) => {
  const pathname = usePathname();
  const firstUpdate = useRef(true);
  const handleRouteChange = () => {
    segmentClient.page();
  };
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    (window as AppWindowType).__ENV__ = appContext;

    // Should only run once.
    segmentInit(() => {
      handleRouteChange();
    }, appContext.user);
  }, []);
  useEffect(() => {
    (window as AppWindowType)?.clarity?.("identify", appContext.user?.email || "unknown",
    // custom user id
    appContext.user?.id || "unknown",
    // user session
    `${appContext.user?.id || ""}: ${pathname}`,
    // page
    appContext.user?.email || "unknown" // hint
    );
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      handleRouteChange();
    }
    Sentry.setUser({
      id: appContext.user?.supplierId,
      email: appContext.user?.email,
      username: appContext.user?.name
    });
  }, [pathname]);
  return <AppProvider appContext={appContext} data-sentry-element="AppProvider" data-sentry-component="ClientProviders" data-sentry-source-file="index.tsx">
      <NavBackBlockerProvider data-sentry-element="NavBackBlockerProvider" data-sentry-source-file="index.tsx">
        <ConfigProvider locale={ANTD_LANGUAGE_MAPPING[appContext.lang]} theme={theme} data-sentry-element="ConfigProvider" data-sentry-source-file="index.tsx">
          <NotificationProvider data-sentry-element="NotificationProvider" data-sentry-source-file="index.tsx">{children}</NotificationProvider>
        </ConfigProvider>
      </NavBackBlockerProvider>
    </AppProvider>;
};