import { useState, useEffect } from "react";
import { LocalStorageKey } from "@/src/enums/common";
import { PIM_LOCAL_STORAGE_PREFIX } from "@/src/constants/localStorage";

interface SetStateOptions {
  skipSetState?: boolean;
}

function useLocalStorageState<T>(
  key: LocalStorageKey,
  initialValue: T
): [
  value: T,
  setValue: (value: T, options?: SetStateOptions) => void,
  isInitialized: boolean,
  refetch: () => T | null,
] {
  const keyWithPrefix = `${PIM_LOCAL_STORAGE_PREFIX}${key}`;
  const [state, setState] = useState<T>(initialValue);
  const [isInitialized, setIsInitialized] = useState(false);

  const setPersistedState = (value: T, options?: SetStateOptions) => {
    try {
      const valueToStore = value instanceof Function ? value(state) : value;

      if (!options?.skipSetState) {
        setState(valueToStore);
      }

      window.localStorage.setItem(keyWithPrefix, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(
        `Error setting localStorage key "${keyWithPrefix}":`,
        error
      );
    }
  };

  const getLocalStorageData = (): T | null => {
    try {
      const item = window.localStorage.getItem(keyWithPrefix);
      if (item) {
        const parsedItem = JSON.parse(item);
        setState(parsedItem);
        return parsedItem as T;
      }
      return null;
    } catch (error) {
      console.error(
        `Error reading localStorage key "${keyWithPrefix}":`,
        error
      );
      return null;
    } finally {
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    getLocalStorageData();
  }, []);

  return [state, setPersistedState, isInitialized, getLocalStorageData];
}

export default useLocalStorageState;
