import { MappedUser } from "@/src/types/user";
import { isServer } from "@/src/utils/environments";
import { fingerprintClient } from "@/src/utils/fingerprint";
import { segmentClient } from "@/src/utils/segment";
const init = async (ready: () => void, user?: MappedUser) => {
  const writeKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
  const cdnUrl = process.env.NEXT_PUBLIC_SEGMENT_CDN_URL;
  const apiHost = process.env.NEXT_PUBLIC_SEGMENT_API_HOST;
  await fingerprintClient.initiate();
  const visitor = fingerprintClient.getVisitorProperties();
  // Setup device related event middlewares
  const userProperties = segmentClient.setUserProperties({
    visitorId: visitor.visitor_id,
    user
  });
  segmentClient.setDefaultProperties({
    properties: userProperties,
    context: {
      os: visitor?.os
    }
  });
  // Initiate segment client
  await segmentClient.initiate({
    writeKey,
    apiHost,
    cdnUrl
  }, () => {
    if (user?.id) {
      segmentClient.identify(user.id, userProperties);
    }
    ready();
  });
};
const segmentInit = async (ready: () => void, user: MappedUser | undefined) => {
  if (isServer()) {
    return;
  }
  await init(ready, user);
};
export default segmentInit;